import {
  Button,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { theme } from '@chakra-ui/theme';
import { useAppDispatch, useAppSelector } from '../lib/hooks';
import { CSSProperties, useEffect, useState } from 'react';
import { AddIcon, DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { addFolder, removeFolder, updateFolder } from '../lib/state/slices/ViewSlice';
import { Folder } from '../lib/state/ViewTypes';
import { Edit2, MoreHorizontal } from 'react-feather';
import ContextMenu, { ContextMenuButton } from './ContextMenu';

export default function FolderSelector(props: {
  style?: CSSProperties;
  onFolderOpen: (folderId: string) => void;
}) {
  const toast = useToast();
  const folders = useAppSelector((state) => state.viewReducer.folders);
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const [folderName, setFolderName] = useState<string>('');
  const {
    isOpen: isCreateDialogOpen,
    onOpen: onCreateDialogOpen,
    onClose: onCreateDialogClose,
  } = useDisclosure();
  const {
    isOpen: isRenameDialogOpen,
    onOpen: onRenameDialogOpen,
    onClose: onRenameDialogClose,
  } = useDisclosure();
  const {
    isOpen: isDeleteDialogOpen,
    onOpen: onDeleteDialogOpen,
    onClose: onDeleteDialogClose,
  } = useDisclosure();
  const [openFolder, setOpenFolder] = useState<string>(folders[0].id);

  useEffect(() => {
    props.onFolderOpen(openFolder);
  }, [openFolder]);

  useEffect(() => {
    if (!folders.find((folder) => folder.id === openFolder)) {
      setOpenFolder(folders[0].id);
    }
  }, [folders]);

  return (
    <div
      style={{
        ...props.style,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
      }}
    >
      <Text fontWeight={700} fontSize={'medium'} marginRight={2}>
        Folder
      </Text>
      <Select
        borderColor={theme.colors.purple[700]}
        borderRadius={'10px'}
        borderWidth={'2px'}
        size={'sm'}
        width={'80%'}
        colorScheme="purple"
        value={openFolder}
        onChange={(e) => {
          setOpenFolder(e.target.value);
          props.onFolderOpen(e.target.value);
        }}
      >
        {folders
          .slice()
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((folder, index) => (
            <option key={folder.id} value={folder.id}>
              {folder.name}
            </option>
          ))}
      </Select>
      <IconButton
        onClick={() => {
          setMenuOpen(!menuOpen);
        }}
        icon={<MoreHorizontal />}
        aria-label="Rename folder"
        size="sm"
        variant="outline"
        colorScheme="purple"
        marginLeft={2}
        position={'relative'}
        overflow={'visible'} />
      {menuOpen && <ContextMenu style={{ position: 'absolute', right: 0, top: 40 }} onClose={() => setMenuOpen(false)}>
        <ContextMenuButton icon={AddIcon} text="Add Folder" onClick={() => {
          setFolderName('');
          onCreateDialogOpen();
          setMenuOpen(false);
        }} />
        <ContextMenuButton icon={Edit2} text="Rename Folder" onClick={() => {
          setFolderName(folders.find((folder) => folder.id === openFolder)?.name || '');
          onRenameDialogOpen();
          setMenuOpen(false);
        }} />
        <ContextMenuButton icon={DeleteIcon} text="Delete Folder" onClick={() => {
          if (folders.length > 1) {
            onDeleteDialogOpen();
          } else {
            toast({
              title: 'Error',
              description: "You can't delete the last folder.",
              status: 'error',
              duration: 5000,
              isClosable: true,
            });
          }
          setMenuOpen(false);
        }} />
      </ContextMenu>}
      <Modal isOpen={isCreateDialogOpen} onClose={onCreateDialogClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create Folder</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={3}>
              <Input
                placeholder="Folder Name"
                value={folderName}
                onChange={(event) => setFolderName(event.target.value)}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    dispatch(addFolder(folderName));
                    onCreateDialogClose();
                  }
                }}
              />
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={() => {
                dispatch(addFolder(folderName));
                onCreateDialogClose();
              }}
            >
              Create
            </Button>
            <Button variant="ghost" onClick={onCreateDialogClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isRenameDialogOpen} onClose={onRenameDialogClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Rename Folder</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={3}>
              <Input
                placeholder="Folder Name"
                value={folderName}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    let folder: Folder = { ...folders.find((folder) => folder.id === openFolder)! };
                    folder.name = folderName;
                    dispatch(updateFolder(folder));
                    onRenameDialogClose();
                  }
                }}
                onChange={(event) => setFolderName(event.target.value)}
              />
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={() => {
                let folder: Folder = { ...folders.find((folder) => folder.id === openFolder)! };
                folder.name = folderName;
                dispatch(updateFolder(folder));
                onRenameDialogClose();
              }}
            >
              Rename
            </Button>
            <Button variant="ghost" onClick={() => onRenameDialogClose()}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isDeleteDialogOpen} onClose={onDeleteDialogClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete Folder</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={3}>
              <Text>
                Are you sure you want to delete the folder "
                {folders.find((folder) => folder.id === openFolder)?.name}"?
              </Text>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="red"
              mr={3}
              onClick={() => {
                dispatch(removeFolder(openFolder!));
                onDeleteDialogClose();
                setOpenFolder(folders[0].id);
              }}
            >
              Delete
            </Button>
            <Button variant="ghost" onClick={() => onDeleteDialogClose()}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
}
