import { Box, Flex, Text, Tooltip } from '@chakra-ui/react';
import {
  RangeSlider,
  RangeSliderTrack,
  RangeSliderFilledTrack,
  RangeSliderThumb,
} from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../lib/hooks';
import { CSSProperties, useEffect, useState, useContext } from 'react';
import { setTimeFilter } from '../../../lib/state/slices/ViewSlice';
import { useSigma } from '@react-sigma/core';
import { GraphViewContext } from '../Index';

function ThumbLabel({ label }: { label?: string }) {
  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        marginTop: '40px',
        // justifyContent: 'center',
        // alignItems: 'center',
      }}
    >
      <Text fontSize="x-small" minWidth="2px" minHeight="15px">
        {label}
      </Text>
    </Box>
  );
}

function TimelineLabel({
  date,
  label,
  position,
}: {
  date: number;
  label: string;
  position: string;
}) {
  return (
    <Flex
      direction="column"
      align="center"
      position="absolute"
      left={position}
      transform="translateX(-50%)"
      px="4px"
      whiteSpace="nowrap"
      overflow="hidden"
    >
      <Tooltip label={`${label}: ${new Date(date).getFullYear()}`} aria-label={label}>
        <Box bg="gray" width="2px" height="10px" mb="1" />
      </Tooltip>
      <Text fontSize="small" whiteSpace="nowrap">
        {String(new Date(date).getFullYear())}
      </Text>
      <Text fontSize="small" whiteSpace="nowrap">
        {label}
      </Text>
    </Flex>
  );
}


export default function TimeFilter({ style }: { style?: CSSProperties }) {
  const dispatch = useDispatch();
  let sigma = useSigma();
  let graph = sigma.getGraph();
  const [upperSelectedDate, setUpperSelectedDate] = useState<number>(new Date().getTime());
  const [lowerSelectedDate, setLowerSelectedDate] = useState<number>(0);
  const [upperDate, setUpperDate] = useState<number>(new Date().getTime());
  const [lowerDate, setLowerDate] = useState<number>(0);
  const [centerNodeDate, setCenterNodeDate] = useState<Date>(new Date());
  const refreshCounter = useAppSelector((state) => state.viewReducer.refreshCounter);

  const graphViewContext = useContext(GraphViewContext);

  //todo: refactor to only use year, also make the filter function use year so that it works with this

  /**
   * Updates the upper and lower date states based on the judgement date attribute of nodes in the graph.
   * If `setSelectedDates` is true, it also updates the selected upper and lower date states.
   * @param {boolean} [setSelectedDates] - Optional flag to determine if selected date states should be updated.
   */
  function setDates(setSelectedDates?: boolean) {
    let sortedNodeDates: Date[] = [...sigma.getGraph().nodes()]
      .map((x) => new Date(graph.getNodeAttribute(x, 'judgementdate')) ?? 0)
      .sort((a, b) => a.getTime() - b.getTime());
    setUpperDate(sortedNodeDates[sortedNodeDates.length - 1]?.getTime());
    setLowerDate(sortedNodeDates[0]?.getTime());
    if (setSelectedDates) {
      setUpperSelectedDate(sortedNodeDates[sortedNodeDates.length - 1]?.getTime());
      setLowerSelectedDate(sortedNodeDates[0]?.getTime());
    }
  }

  useEffect(() => {
    setCenterNodeDate(graph.nodes().length == 0
      ? new Date(0)
      : new Date(
        graph.getNodeAttribute(
          graph.nodes().find((x) => graph.getNodeAttribute(x, 'id') === graphViewContext.centerNode),
          'judgementdate',
        ),
      ))
    setDates(true);
  }, [refreshCounter]);

  //Initializes the upper and lower dates
  useEffect(() => {
    setCenterNodeDate(graph.nodes().length == 0
      ? new Date(0)
      : new Date(
        graph.getNodeAttribute(
          graph.nodes().find((x) => graph.getNodeAttribute(x, 'id') === graphViewContext.centerNode),
          'judgementdate',
        ),
      ))
    setDates(true);

    //Adds a listener when a node is added and updates the upper and lower dates
    sigma.getGraph().on('nodeAdded', () => {
      setDates(false);
    });
    sigma.getGraph().on('nodeDropped', () => { });
  }, []);

  const isCenterAtBounds =
    centerNodeDate.getFullYear() === new Date(lowerDate).getFullYear() ||
    centerNodeDate.getFullYear() === new Date(upperDate).getFullYear();

  const calculatePosition = (date: number) => {
    return `${((date - lowerDate) / (upperDate - lowerDate)) * 100}%`;
  };

  const handleSliderChange = (val: number[]) => {
    setLowerSelectedDate(val[0]);
    setUpperSelectedDate(val[1]);
  };

  const handleSliderChangeEnd = (val: number[]) => {
    dispatch(
      setTimeFilter({
        timeFilter: { lower: val[0], upper: val[1] },
        graphViewId: graphViewContext.id,
      }),
    );
  };

  return (
      <Box style={{ ...containerStyle, ...style }}>
        <div style={flexRowStyle}>
          <Text fontSize="small" as="b" textAlign="center">
            Show cases from
          </Text>
          <Box style={sliderContainerStyle}>
            <RangeSlider
              width="100%"
              min={lowerDate}
              max={upperDate}
              step={86400000}
              defaultValue={[lowerDate, upperDate]}
              colorScheme="gray"
              onChange={handleSliderChange}
              onChangeEnd={handleSliderChangeEnd}
            >
              <RangeSliderTrack bg="#A0AEC0">
                <RangeSliderFilledTrack bg="purple" />
              </RangeSliderTrack>
              <RangeSliderThumb index={0} style={thumbStyle}>
                <ThumbLabel
                  label={
                    new Date(lowerSelectedDate).getFullYear() > new Date(lowerDate).getFullYear()
                      ? new Date(lowerSelectedDate).getFullYear().toString()
                      : undefined
                  }
                />
              </RangeSliderThumb>
              <RangeSliderThumb index={1} style={thumbStyle}>
                <ThumbLabel
                  label={
                    new Date(upperSelectedDate).getFullYear() < new Date(upperDate).getFullYear()
                      ? new Date(upperSelectedDate).getFullYear().toString()
                      : undefined
                  }
                />
              </RangeSliderThumb>
              <Box position="absolute" width="100%" mt="16px">
                {[
                  { date: lowerDate, label: 'Oldest case' },
                  ...(isCenterAtBounds
                    ? []
                    : [{ date: centerNodeDate.getTime(), label: 'Center case' }]),
                  { date: upperDate, label: 'Newest case' },
                ].map(({ date, label }, index) => (
                  <TimelineLabel
                    key={label + index}
                    date={date}
                    label={label}
                    position={calculatePosition(date)}
                  />
                ))}
              </Box>
            </RangeSlider>
          </Box>
        </div>
      </Box>
  );
}

const containerStyle: CSSProperties = {
  width: '96%',
  padding: '12px',
  paddingRight: '45px',
  paddingBottom: '40px',
  marginBottom: '0px',
  backgroundColor: 'rgba(255,255,255,0.9)',
  borderRadius: '5px',
};

const flexRowStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'start',
};

const sliderContainerStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  marginLeft: '30px',
};

const thumbStyle: CSSProperties = {
  width: '10px',
  height: '25px',
  borderRadius: '10px',
  background: 'grey',
  position: 'absolute',
  right: '20px',
};
