import { SigmaContainer } from '@react-sigma/core';
import { NodeBorderProgram } from '@sigma/node-border';
import Graph from 'graphology';
import { SerializedGraph } from 'graphology-types';
import { Attributes, createContext, useEffect, useState } from 'react';
import { EdgeArrowProgram } from 'sigma/rendering';
import { Graph as GraphAPIType, importancetypes, Node, Relationship } from '../../lib/api/APITypes';
import {
  useLazyGetNeighboursQuery,
  useLazyGetRecommendedNeighboursQuery,
} from '../../lib/api/GraphAPI';
import { copyToMutableGraph } from '../../lib/GraphFactory';
import { useAppDispatch, useAppSelector } from '../../lib/hooks';
import {
  incrementRefreshCounter,
  removeFloatingDoc,
  setGraph as setGraphAction,
} from '../../lib/state/slices/ViewSlice';
import { store } from '../../lib/state/Store';
import { GraphView } from '../../lib/state/ViewTypes';
import LoadingScreen from '../LoadingScreen';
import Filter from './controls/Filter';
import NodeContextMenu from './controls/NodeContextMenu';
import QuickFilter from './controls/QuickFilter';
import TimeFilter from './controls/TimeFilter';
import GraphEvents from './Events';
import FloatingDocument from './FloatingDocument';
import Header from './Header';
import ListView from './ListView';
import { v4 as uuidv4 } from 'uuid';

const GraphViewContext = createContext<GraphView>({
  centerNode: '',
  id: '',
  name: '',
  floatingDocuments: [],
  serializedGraph: null,
  filter: {
    decisionLevel: [],
    importance: [],
    caseDetail: [],
    articles: [],
    timeFilter: { lower: 0, upper: 0 },
    countrys: [],
  },
});

export const GraphLayout = (props: { isActive?: boolean; graphViewId: string }) => {
  const dispatch = useAppDispatch();
  const graphView: GraphView = useAppSelector(
    (state) => state.viewReducer.graphViews[props.graphViewId],
  );
  if (!graphView) {
    console.error(`GraphView with id ${props.graphViewId} not found`);
  }

  let serializedGraph = useAppSelector((state) =>
    !state.viewReducer.graphViews[graphView?.id].serializedGraph
      ? null
      : state.viewReducer.graphViews[graphView?.id].serializedGraph,
  );

  const [loadNeighbours, loadNeighboursResult, lastPromiseInfoLoadNeighbours] =
    useLazyGetNeighboursQuery({});
  const [
    loadRecommendedNeighbours,
    loadRecommendedNeighboursResult,
    lastPromiseInfoLoadRecommendedNeighbours,
  ] = useLazyGetRecommendedNeighboursQuery({});

  const [graphIsLoading, setGraphIsLoading] = useState(true);

  const [isListView, setIsListView] = useState(false);
  const toggleView = () => setIsListView((prev) => !prev);

  const [graph, setGraph] = useState<Graph<Attributes, Attributes, Attributes>>(
    new Graph({ type: 'mixed' }),
  );

  useEffect(() => {
    if (!serializedGraph) {
      loadNeighbours({ id: graphView!.centerNode, level: 1 });
    } else {
      setGraph(copyToMutableGraph(Graph.from(serializedGraph)));
      dispatch(incrementRefreshCounter());
    }
  }, [serializedGraph]);

  useEffect(() => {
    let d: GraphAPIType = { edges: [], nodes: [] };

    if (loadNeighboursResult.data) {
      d.edges.push(...loadNeighboursResult.data.edges);
      d.nodes.push(...loadNeighboursResult.data.nodes);
    }
    if (loadRecommendedNeighboursResult.data) {
      // d.edges.push(...loadRecommendedNeighboursResult.data.edges.map);
      d.nodes.push(...loadRecommendedNeighboursResult.data.nodes);
      let originNode = d.nodes.find(
        (node) => node.properties.id === lastPromiseInfoLoadRecommendedNeighbours.lastArg,
      );
      loadRecommendedNeighboursResult.data.nodes.forEach((node, index) => {
        d.edges.push({
          elementId: uuidv4(),
          startNodeElementId: originNode!.elementId,
          endNodeElementId: node.elementId,
          type: 'recommend',
          properties: {
            weight: loadRecommendedNeighboursResult.data!.distances[index],
          },
        } as Relationship);
      });
    }

    if (d.nodes.length === 0) {
      setGraphIsLoading(false);
      return;
    }

    if (d) {
      setGraphIsLoading(true);

      // Create a new Web Worker
      const worker = new Worker(new URL('./GraphWorker.ts', import.meta.url));

      // Send data to the worker
      worker.postMessage({
        data: d,
        centerNode: graphView!.centerNode,
        serializedMergeGraph: serializedGraph,
      });

      // Handle the result from the worker
      worker.onmessage = (
        event: MessageEvent<SerializedGraph<Attributes, Attributes, Attributes>>,
      ) => {
        dispatch(
          setGraphAction({
            graphViewId: props.graphViewId,
            serializedGraph: event.data,
            centerNode: graphView.centerNode,
          }),
        );
        setGraphIsLoading(false);
        worker.terminate(); // Clean up the worker
      };

      // Handle errors
      worker.onerror = (error) => {
        console.error('Worker error:', error);
        setGraphIsLoading(false);
        worker.terminate();
      };

      return () => {
        worker.terminate();
      };
    }
  }, [loadNeighboursResult.data, loadRecommendedNeighboursResult.data]);

  const [hoveredNode, onHover] = useState<{
    nodeId: string;
    id: string;
  }>();
  const [nodeContextMenu, setNodeContextMenu] = useState<{
    nodeId: string;
    id: string;
  }>();

  let floatingDocuments = useAppSelector(
    (state) => state.viewReducer.graphViews[props.graphViewId].floatingDocuments,
  );
  const openTools = useAppSelector((state) => state.viewReducer.toolOpenStatus);

  return (
    <div style={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column' }}>
      <GraphViewContext.Provider value={graphView}>
        <Header
          graphName={store.getState().viewReducer.graphViews[props.graphViewId].name}
          toggleView={toggleView}
          isListView={isListView}
        />
        <style>
          {`
            .sigma-container {
              background-color: #E2E8F0;
              flex-grow: 1;
            }
          `}
        </style>
        {isListView ? (
          <ListView
            data={{
              nodes: graph.nodes().map((node) => {
                const attrs = graph.getNodeAttributes(node);
                return {
                  elementId: node,
                  labels: [''],
                  properties: {
                    id: attrs.id,
                    label: attrs.label,
                    appno: attrs.appno,
                    chambertype: attrs.chambertype,
                    conclusion: '',
                    country: attrs.country,
                    description: '',
                    importance: attrs.importance,
                    judgementdate: attrs.judgementdate,
                    keywords: attrs.keywords,
                    nonviolation: attrs.nonviolation,
                    separateopinion: '',
                    summary: '',
                    url: attrs.url,
                    violation: attrs.violation,
                  },
                };
              }),
              edges: [],
            }}
            key={`listView${props.graphViewId}`}
          >
            <Filter style={{ position: 'absolute', top: '20px', right: '20px', width: '300px' }} />{' '}
          </ListView>
        ) : (
          <>
            {graphIsLoading ? (
              <LoadingScreen
                style={{
                  height: '-webkit-fill-available',
                  width: '-webkit-fill-available',
                  position: 'absolute',
                  zIndex: 1000,
                }}
                backdrop={0.8}
              />
            ) : null}
            <SigmaContainer
              style={{ height: '100%', width: '100%', display: 'flex', position: 'relative' }}
              settings={{
                //hoverRenderer: () => {},
                defaultEdgeType: 'arrow',
                edgeProgramClasses: { arrow: EdgeArrowProgram },
                nodeProgramClasses: {
                  border: NodeBorderProgram,
                },
                allowInvalidContainer: true,
              }}
              key={`sigmaContainer${props.graphViewId}`}
              graph={graph}
            >
              <GraphEvents
                onHoverNode={onHover}
                isActive={props.isActive}
                setNodeContextMenu={setNodeContextMenu}
              />
              {floatingDocuments.map((doc) => (
                <FloatingDocument
                  elementId={doc.nodeId}
                  key={doc.id}
                  id={doc.id}
                  onClose={() => {
                    dispatch(
                      removeFloatingDoc({
                        graphViewId: graphView.id,
                        floatingDocId: doc.id,
                      }),
                    );
                  }}
                />
              ))}
              <TimeFilter style={{ position: 'absolute', bottom: '20px', left: '2%' }} />
              <Filter
                style={{ position: 'absolute', top: '20px', right: '20px', width: '300px' }}
              />
              <QuickFilter style={{ position: 'absolute', top: '20px', left: '20px' }} />
              {nodeContextMenu ? (
                <NodeContextMenu
                  id={nodeContextMenu.id}
                  nodeId={nodeContextMenu.nodeId}
                  key={nodeContextMenu.nodeId}
                  loadNeighbours={(id: string) => {
                    loadNeighbours({ id, level: 1 });
                  }}
                  recommendNeighbours={(id: string, nodeId: string) => {
                    loadRecommendedNeighbours(id);
                  }}
                />
              ) : null}
            </SigmaContainer>
          </>
        )}
      </GraphViewContext.Provider>
    </div>
  );
};

export { GraphViewContext };
