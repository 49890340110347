import { Stack, Text } from '@chakra-ui/react';
import { useAppDispatch, useAppSelector } from '../../lib/hooks';
import { motion } from 'framer-motion';
import {
  incrementRefreshCounter,
  removeDoc,
  setToolOpenStatus,
} from '../../lib/state/slices/ViewSlice';
import theme from '../../theme';
import { ChevronRight } from 'react-feather';
import { useState } from 'react';
import DocumentCard from '../DocumentCard';
import FolderSelector from '../FolderSelector';

export default function SavedDocumentsList() {
  const open = useAppSelector((state) => state.viewReducer.toolOpenStatus.savedCases);
  const folders = useAppSelector((state) => state.viewReducer.folders);
  const toolOpenStatus = useAppSelector((state) => state.viewReducer.toolOpenStatus);
  const dispatch = useAppDispatch();
  const [openFolder, setOpenFolder] = useState<string | null>('default');
  const variants = {
    open: { width: '460px', padding: '10px' },
    closed: { width: '30px', padding: '0px' },
  };
  const expand_button_variants = {
    open: { transform: 'rotate(0deg)' }, // rotate button by 180deg
    closed: { transform: 'rotate(180deg)' },
  };
  const header_variants = {
    open: { opacity: 1, display: 'block', whiteSpace: 'nowrap' }, // disallow linewrap with whitespace: nowrap
    closed: { display: 'none', opacity: 0, whiteSpace: 'nowrap' },
  };

  return (
    <motion.div variants={variants} animate={open ? 'open' : 'closed'}>
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'clip',
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent={'space-between'}
          minHeight={'46px'}
          width={'100%'}
        >
          <Stack direction={'row'} alignItems={'center'} width={'100%'}>
            <motion.div variants={expand_button_variants} animate={open ? 'open' : 'closed'}>
              <ChevronRight
                onClick={() => {
                  dispatch(incrementRefreshCounter());
                  dispatch(setToolOpenStatus({ ...toolOpenStatus, savedCases: !open }));
                }}
                style={{ cursor: 'pointer' }}
              />
            </motion.div>

            <motion.div variants={header_variants} animate={open ? 'open' : 'closed'} style={{width: '100%'}}>
              <FolderSelector onFolderOpen={(newOpenFolder) => setOpenFolder(newOpenFolder)} style={{ width: '100%' }}/>
            </motion.div>
          </Stack>
        </Stack>
        <motion.div
          variants={header_variants}
          style={{ flex: 1, overflowY: 'scroll', scrollbarWidth: 'thin' }}
        >
          <Stack
            direction={'column'}
            justifyContent={'center'}
            alignItems={'center'}
            padding={'10px'}
          >
            {openFolder
              ? folders
                  .find((folder) => folder.id === openFolder)
                  ?.documents.map((doc) => (
                    <DocumentCard
                      key={doc}
                      id={doc}
                      nodeId=""
                      onClose={() => dispatch(removeDoc({ folderId: openFolder, nodeId: doc }))}
                    />
                  ))
              : null}
          </Stack>
        </motion.div>
      </div>
    </motion.div>
  );
}
