import { useAuth } from 'oidc-react';
import { store } from './state/Store';
import { token } from './state/slices/AuthSlice';
import SplashScreen from '../pages/SplashScreen';
import { useEffect } from 'react';
import { useAppSelector } from './hooks';

export default function AuthHandler(props: { children: React.ReactNode }) {
  const auth = useAuth();
  const access_token = useAppSelector((state) => state.authReducer.accesstoken);
  // console.log(auth.userData);

  useEffect(() => {
    if (auth.userData) {
      store.dispatch(token(auth.userData!!.access_token));
    } else if (!auth.userData && !auth.isLoading) {
      auth.signIn();
    }
  }, [auth.userData?.access_token]);

  return <>{auth.isLoading || !auth.userData || access_token === '' ? <SplashScreen /> : props.children}</>;
}
