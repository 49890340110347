import {
  Card,
  Grid,
  GridItem,
  Icon,
  IconProps,
  Stack,
  Text,
  Tooltip,
  theme,
  Divider,
} from '@chakra-ui/react';
import { CSSProperties, useContext } from 'react';
import { getNodeColor } from '../../../lib/GraphFactory';
import { useAppSelector, useAppDispatch } from '../../../lib/hooks';
import {
  CaseDetailType,
  ImportanceNames,
  Importancetype,
  importancetypes,
  caseDetailTypes,
  CaseDetailNames,
  CaseDetailColors,
} from '../../../lib/api/APITypes';
import { importanceLevelTooltips, caseDetailTooltips } from '../../../strings/tooltips';
import {
  addImportanceFilter,
  removeImportanceFilter,
  addCaseDetailFilter,
  removeCaseDetailFilter,
} from '../../../lib/state/slices/ViewSlice';
import { GraphViewContext } from '../Index';

function CircleIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <circle cx="12" cy="12" r="10" fill="currentColor" />
    </Icon>
  );
}

function CircleOutlineIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
    </Icon>
  );
}

function QuestionIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <circle cx="12" cy="12" r="12" fill="currentColor" />
      <svg fill="#000000" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
        <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
        <g id="SVGRepo_iconCarrier">
          <path d="M12 4C9.243 4 7 6.243 7 9h2c0-1.654 1.346-3 3-3s3 1.346 3 3c0 1.069-.454 1.465-1.481 2.255-.382.294-.813.626-1.226 1.038C10.981 13.604 10.995 14.897 11 15v2h2v-2.009c0-.024.023-.601.707-1.284.32-.32.682-.598 1.031-.867C15.798 12.024 17 11.1 17 9c0-2.757-2.243-5-5-5zm-1 14h2v2h-2z"></path>
        </g>
      </svg>
    </Icon>
  );
}

export default function QuickFilter(props: { style: CSSProperties }) {
  let dispatch = useAppDispatch();
  const graphView = useContext(GraphViewContext);
  let importanceFilter = useAppSelector(
    (state) => state.viewReducer.graphViews[graphView.id].filter.importance,
  );
  let caseDetailFilter = useAppSelector(
    (state) => state.viewReducer.graphViews[graphView.id].filter.caseDetail,
  );

  const toggleImportanceOption = (value: Importancetype) => {
    const filterFunction = importanceFilter.includes(value)
      ? removeImportanceFilter
      : addImportanceFilter;
    dispatch(
      filterFunction({
        graphViewId: graphView.id,
        importance: value,
      }),
    );
  };

  const toggleCaseDetailOption = (value: CaseDetailType) => {
    const filterFunction = caseDetailFilter.includes(value)
      ? removeCaseDetailFilter
      : addCaseDetailFilter;
    dispatch(
      filterFunction({
        graphViewId: graphView.id,
        caseDetailLevel: value,
      }),
    );
  };

  return (
    <Card
      style={{
        ...props.style,
        padding: '10px',
      }}
    >
      <Grid templateRows="repeat(2, 1fr)" gap={2}>
        <GridItem colSpan={1} rowSpan={2} gap={2}>
          <Stack direction="row" alignItems="center">
            <Text>Importance</Text>
            <Tooltip
              label={
                <Text>
                  Cases are divided into four categories by the European Court. The highest level of
                  importance is Case Reports/Key Cases, followed by levels 1, 2 and 3. The
                  classification by levels 1, 2 and 3 remains provisional until the Bureau has
                  decided whether a case should appear in the Court's official reports series. For
                  example, a case that was provisionally classified as level 1 but ultimately
                  selected for the official reports will change category.
                </Text>
              }
            >
              <div>
                <QuestionIcon boxSize="17px" color="gray.400" />
              </div>
            </Tooltip>
          </Stack>
          {importancetypes.map((e, i) => {
            return (
              <Stack
                key={`importanceTypeToggleItemWrapper${i}`}
                direction="row"
                onClick={() => toggleImportanceOption(e)}
                alignItems="center"
                cursor="pointer"
              >
                <CircleIcon
                  boxSize="17px"
                  color={
                    importanceFilter.includes(e)
                      ? getNodeColor(e.toString())
                      : theme.colors.gray[400]
                  }
                />
                <Tooltip label={importanceLevelTooltips[e]}>
                  <Text
                    key={`importanceTypeToggleItemText${i}`}
                    textColor={importanceFilter.includes(e) ? 'black' : theme.colors.gray[400]}
                    userSelect="none"
                  >
                    {ImportanceNames[e]}
                  </Text>
                </Tooltip>
              </Stack>
            );
          })}
        </GridItem>

        <Divider my={2} />

        <GridItem colSpan={1} rowSpan={2}>
          <Stack direction="row" alignItems="center">
            <Text>Case Details</Text>
            <Tooltip
              label={
                <Text>
                  Filter out judgements based on the case details. This could for example be cases
                  where there has been found a violation, a non-violation, both, or miscellaneous -
                  for example pecuniary damage.
                </Text>
              }
            >
              <div>
                <QuestionIcon boxSize="17px" color="gray.400" />
              </div>
            </Tooltip>
          </Stack>
          {caseDetailTypes.map((index, e) => {
            return (
              <Stack
                key={`CaseDetailToggleItemWrapper${index}`}
                direction="row"
                alignItems="center"
                onClick={() => toggleCaseDetailOption(index)}
                cursor="pointer"
              >
                <CircleOutlineIcon
                  key={`importanceTypeToggleItemIcon${index}`}
                  boxSize="17px"
                  color={
                    caseDetailFilter.includes(index)
                      ? CaseDetailColors[index]
                      : theme.colors.gray[400]
                  }
                />
                <Tooltip label={caseDetailTooltips[e]}>
                  <Text
                    key={`importanceTypeToggleItemText${index}`}
                    textColor={caseDetailFilter.includes(index) ? 'black' : theme.colors.gray[400]}
                    userSelect="none"
                  >
                    {CaseDetailNames[index]}
                  </Text>
                </Tooltip>
              </Stack>
            );
          })}
        </GridItem>
      </Grid>
    </Card>
  );
}
