import { Grid, Select, Text, theme } from '@chakra-ui/react';
import DocumentCard from '../components/DocumentCard';
import { useAppDispatch, useAppSelector } from '../lib/hooks';
import { useState } from 'react';
import { removeDoc } from '../lib/state/slices/ViewSlice';
import FolderSelector from '../components/FolderSelector';

function Header() {
  return (
    <div style={{ width: '100%', padding: '20px' }}>
      <Text fontWeight={700} fontSize={'xx-large'}>
        Saved Cases
      </Text>
    </div>
  );
}

export default function SavedCasesPage() {
  const [openFolder, setOpenFolder] = useState<string | null>('default');
  const dispatch = useAppDispatch();
  const folders = useAppSelector((state) => state.viewReducer.folders);

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        padding: '20px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        overflow: 'hidden',
      }}
    >
      <Header />
      <FolderSelector onFolderOpen={setOpenFolder} />
      <Grid
        style={{
          height: '100%',
          backgroundColor: theme.colors.gray[100],
          marginTop: '20px',
          borderRadius: '10px',
          padding: '10px',
          overflowY: 'auto',
          scrollbarWidth: 'thin',
        }}
        templateColumns="repeat(3, 1fr)"
        gap={4}
      >
        {openFolder ? folders.find((folder) => folder.id === openFolder)?.documents.map((doc) => <DocumentCard key={doc} id={doc} nodeId="" onClose={() => dispatch(removeDoc({ folderId: openFolder, nodeId: doc }))} />) : null}
      </Grid>
    </div>
  );
}
