import { Box, Button, Center, Icon, Stack, Text, useToast } from '@chakra-ui/react';
import { useAppDispatch, useAppSelector } from '../../../lib/hooks';
import { getDefaultGraphView } from '../../../lib/GraphFactory';
import { addNewGraphView, saveDoc } from '../../../lib/state/slices/ViewSlice';
import { useSigma } from '@react-sigma/core';
import { useEffect, useState } from 'react';
import { Circle, Delete, Folder, Maximize2, Save } from 'react-feather';
import { CenterFocusStrongRounded } from '@mui/icons-material';
import { AISparcles } from '../../../components/DocumentCard';
import ContextMenu, { ContextMenuButton } from '../../../components/ContextMenu';

export default function NodeContextMenu(props: {
  id: string;
  nodeId: string;
  loadNeighbours: (id: string) => void;
  recommendNeighbours: (id: string, nodeId: string) => void;
}) {
  const dispatch = useAppDispatch();
  const folders = useAppSelector((state) => state.viewReducer.folders);
  const toast = useToast();
  const graph = useSigma().getGraph();

  let sigma = useSigma();
  const [position, setPosition] = useState(() => {
    let attributes = sigma.getGraph().getNodeAttributes(props.nodeId);
    let viewportPosition = sigma.graphToViewport({ x: attributes.x, y: attributes.y });
    viewportPosition.x = viewportPosition.x - 200;
    viewportPosition.y = viewportPosition.y + 10;
    return viewportPosition;
  });
  useEffect(() => {
    sigma.on('afterRender', () => {
      let attributes = sigma.getGraph().getNodeAttributes(props.nodeId);
      let viewportPosition = sigma.graphToViewport({ x: attributes.x, y: attributes.y });
      viewportPosition.x = viewportPosition.x - 200;
      viewportPosition.y = viewportPosition.y + 10;
      setPosition(viewportPosition);
    });
  }, []);

  return (
    <div
      style={{
        position: 'absolute',
        top: position.y,
        left: position.x,
      }}
    >
      <ContextMenu onClose={() => {}}>
        <ContextMenuButton
          icon={Circle}
          text="New Center"
          onClick={() => {
            let newGraphView = getDefaultGraphView(props.id);
            newGraphView.name = graph
              .getNodeAttribute(props.nodeId, 'label')
              .replace('CASE OF', '');
            dispatch(addNewGraphView(newGraphView));
            toast({
              title: 'Graph opened',
              description: 'Your Graph has been opened in the sidebar',
              status: 'info',
              duration: 3000,
              isClosable: true,
            });
          }}
        />
        <ContextMenuButton
          icon={Maximize2}
          text="Expand Neighbours"
          onClick={() => {
            props.loadNeighbours(props.id);
            toast({
              title: 'Neigbours loaded',
              description: 'The new neighbours might not be visible due to filters',
              status: 'info',
              duration: 3000,
              isClosable: true,
            });
          }}
        />
        <ContextMenuButton
          icon={AISparcles}
          text="Recommend Neighbours"
          onClick={() => {
            props.recommendNeighbours(props.id, props.nodeId);
            toast({
              title: 'Neigbours recommended',
              description: 'The new neighbours might not be visible due to filters',
              status: 'info',
              duration: 3000,
              isClosable: true,
            });
          }}
        />
        <ContextMenuButton
          icon={Save}
          text="Save to Folder"
          onClick={() => {}}
          submenu={
            <ContextMenu>
              {folders
                .slice()
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((folder, index) => (
                  <ContextMenuButton
                    key={folder.id}
                    icon={Folder}
                    text={folder.name}
                    onClick={() => {
                      dispatch(saveDoc({ folderId: folder.id, nodeId: props.id }));
                      toast({
                        title: 'Document saved',
                        description: 'The document has been saved to ' + folder.name,
                        status: 'info',
                        duration: 3000,
                        isClosable: true,
                      });
                    }}
                  />
                ))}
            </ContextMenu>
          }
        />
      </ContextMenu>
      <Stack>
        {/*
         */}
        {/* <Menu>
          <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
            Save to Folder
          </MenuButton>
          <MenuList>
            {folders.map((folder) => (
              <MenuItem
                key={folder.id}
                onClick={() => {
                  dispatch(saveDoc({ folderId: folder.id, nodeId: props.id }));
                  toast({
                    title: 'Document saved',
                    description: 'The document has been saved to ' + folder.name,
                    status: 'info',
                    duration: 3000,
                    isClosable: true,
                  });
                }}
              >
                {folder.name}
              </MenuItem>
            ))}
          </MenuList>
        </Menu> */}
        {/*
         */}
      </Stack>
    </div>
  );
}
