import { motion } from 'framer-motion';
import { CSSProperties } from 'react';


export default function SplashScreen({ style, backdrop }: { style?: CSSProperties, backdrop?: number } = {}) {
    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          background: `rgba(255,255,255,${backdrop || 0})`,
          ...style,
        }}
      >
        <motion.div
      className="box"
      animate={{
        scale: [1, 2, 2, 1, 1],
        rotate: [0, 0, 180, 180, 0],
        borderRadius: ["0%", "0%", "50%", "50%", "0%"]
      }}
      transition={{
        duration: 2,
        ease: "easeInOut",
        times: [0, 0.2, 0.5, 0.8, 1],
        repeat: Infinity,
        repeatDelay: 1
      }}
    >

                <img src="/logo512.png" alt="logo" style={{ height: '100px', width: '100px' }} />

    </motion.div>
      </div>
    );
  }
  