import { Grid, Input, InputGroup, InputLeftElement, Text } from '@chakra-ui/react';
import { useAuth } from 'oidc-react';
import theme from '../theme';
import { CSSProperties } from 'react';
import { Search } from 'react-feather';
import DocumentCard from '../components/DocumentCard';

function Header() {
  const auth = useAuth();
  return (
    <div style={{ height: '247px', width: '100%', marginTop: '97px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', borderRadius: '10px', background: 'linear-gradient(0deg, #9F7AEA 0.51%, #E4DAF9 54%, #FFFFFF 97%)' }}>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
        <Text fontWeight={700} color={theme.colors.purple[500]} fontSize="xxx-large">
          {auth.userData?.profile.given_name}'
        </Text>
        {!auth.userData?.profile.given_name?.endsWith('s') && !auth.userData?.profile.given_name?.endsWith('z') ? (
          <Text fontWeight={700} color={theme.colors.purple[500]} fontSize="xxx-large">
            s
          </Text>
        ) : null}{' '}
        <Text fontWeight={700} fontSize="xxx-large">
          &nbsp;research page
        </Text>
      </div>
      {/* <Searchbar style={{ marginTop: '20px' }} /> */}
    </div>
  );
}

function Searchbar(props: { style?: CSSProperties }) {
  return (
    <div style={{ ...props.style }}>
      <InputGroup>
        <InputLeftElement>
          <Search />
        </InputLeftElement>
        <Input placeholder="Do a quick search for a saved case..." width={'536px'} backgroundColor={'white'} />
      </InputGroup>
    </div>
  );
}

function PreviouslyViewedCases() {
  const doc_ids = ['hudoc:001-85276', 'hudoc:001-99015', 'hudoc:001-61900', 'hudoc:001-81476', 'hudoc:001-61875', 'hudoc:001-60448', 'hudoc:001-97597', 'hudoc:001-77543', 'hudoc:001-68790'];
  return (
    <div style={{ height: '100%', display: 'flex', margin: '20px', flexDirection: 'column', backgroundColor: theme.colors.gray[100], borderRadius: '10px', padding: '10px', overflowY: 'auto' }}>
      <Text fontWeight={600} color={theme.colors.purple[700]} fontSize="large" marginLeft={'50px'} marginTop={'30px'}>
        Recommended Cases
      </Text>
      <Grid style={{
        height: '100%',
        backgroundColor: theme.colors.gray[100],
        borderRadius: '10px',
        padding: '10px',
        overflowY: 'scroll',
        scrollbarWidth: 'thin',
        justifyItems: 'center'
      }}
        templateColumns={"repeat(auto-fit, minmax(380px, 1fr))"}
        gap={4}>
        {/* <IconButton icon={<ArrowLeftIcon />} aria-label="Left" /> */}
        {doc_ids.map((doc_id) => (
          <DocumentCard key={doc_id} id={doc_id} />
        ))}
        {/* <IconButton icon={<ArrowRightIcon />} aria-label="Right" /> */}
      </Grid>
    </div>
  );
}

export default function ResearchPage() {
  return (
    <div style={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
      <Header />
      <PreviouslyViewedCases />
    </div>
  );
}
