import { useLocation } from 'react-router-dom';
import { useAppSelector } from '../lib/hooks';
import { GraphLayout } from './GraphPage/Index';

export default function GraphPage() {
  const graphView = useAppSelector((state) => state.viewReducer.graphViews);
  const location = useLocation();
  if (Object.values(graphView).length === 0)
    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        No Graphs available
      </div>
    );
  if (location.pathname === '/graph') return <div>No Graph Selected</div>;
  return <GraphLayout key={parseInt(location.pathname.split('/graph/')[1])} graphViewId={location.pathname.split('/graph/')[1]} />;
}
