import { ReactElement, useRef } from 'react';
import theme from '../theme';
import {
  Box,
  Button,
  Divider,
  Icon,
  IconButton,
  Image,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Spacer,
  Stack,
  Text,
  Tooltip,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react';
import {
  Home,
  Search,
  Heart,
  Bookmark,
  Settings,
  LogOut,
  HelpCircle,
  MessageSquare,
  MoreHorizontal,
} from 'react-feather';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../lib/hooks';
import { useAuth } from 'oidc-react';
import { removeGraphView } from '../lib/state/slices/ViewSlice';

const navItemsGroup = [
  { 
    id: 'research',
    label: 'Home',
    label_short: 'Home',
    path: '/research',
    icon: <Home />
  },
  { 
    id: 'search',
    label: 'Search',
    label_short: 'Search',
    path: '/search',
    icon: <Search />
  },
  { 
    id: 'recommended_cases',
    label: 'Recommended cases',
    label_short: 'Recommended',
    path: '/recommended cases',
    icon: <Heart />,
    disabled: true,
  },
  { 
    id: 'saved_cases',
    label: 'Saved cases',
    label_short: 'Saved',
    path: '/saved_cases',
    icon: <Bookmark />
  },
];

interface navItemButton {
  id: string;
  text: string;
  textShort: string;
  active: boolean;
  disabled: boolean | undefined;
  isOpen: boolean;
  icon: ReactElement | boolean;
  textSize: string;
  textColor: string;
  tabIndex: number;
  showMore: boolean;
  onClick: () => void;
}

function NavItemButton({ ...props }: navItemButton) {
  
  const text = useBreakpointValue({ base: props.textShort, lg: props.text });
  
  const disclosure = useDisclosure();
  const initialFocusRef = useRef(null);
  
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  
  return (
    <Stack direction="row" gap={0}>
      <Button
        variant="ghost"
        width="full"
        maxHeight="8"
        minHeight="8"
        padding={{
          xs: '5',
          lg: '7',
          xl: '7',
        }}
        // leftIcon={
        // }
        cursor="pointer"
        bg={props.active ? theme.colors.purple[100] : ''}
        onClick={props.disabled ? () => {} : props.onClick}
        _hover={{ bg: props.disabled ? theme.colors.gray[400] : theme.colors.purple[200] }}
        disabled={props.disabled}
        tabIndex={props.tabIndex}
      >
        <Stack
          direction="row"
          width="full"
          alignItems="center"
          justifyContent={props.isOpen ? 'flex-start' : 'center'}
        >
          <Tooltip
            label={props.disabled ? 'Coming soon' : props.text}
            openDelay={props.disabled ? 0 : 1000}
            placement="end"
          >
            <Stack direction="row">
              {props.icon ? (
                <Icon height={6} width={6}>
                  {props.icon}
                </Icon>
              ) : undefined}
              <Text
                fontSize={{
                  base: 'small',
                  lg: 'medium',
                  xl: 'medium',
                }}
                // style={{ "bul" }}
                textAlign={props.isOpen ? 'left' : 'center'}
                alignContent="center"
                transition="hideBelow 1s"
                width={180}
                fontWeight={600}
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
                textColor={props.textColor}
                display={props.isOpen ? 'block' : 'none'}
              >
                {text}
              </Text>
            </Stack>
          </Tooltip>
        </Stack>
      </Button>
      {props.showMore && props.isOpen ? (
        <Popover
          initialFocusRef={initialFocusRef}
          isOpen={disclosure.isOpen}
          onOpen={disclosure.onOpen}
          onClose={disclosure.onClose}
          placement="right"
        >
          <PopoverTrigger>
            <IconButton
              size="sm"
              bg="transparent"
              aria-label={'sidebar_button_options' + props.id}
              aria-hidden={false}
              icon={<MoreHorizontal />}
              tabIndex={props.tabIndex + 1}
              _hover={{ bg: props.disabled ? theme.colors.gray[400] : theme.colors.purple[200] }}
              onClick={() => disclosure.onOpen()}
            />
          </PopoverTrigger>

          <PopoverContent width="fit-content" p={0}>
            <PopoverBody>
              <Stack direction="column">
                <Button
                  width="100%"
                  onClick={() => {
                    if (location.pathname === `/graph/${props.id}`) {
                      navigate('/research');
                    }
                    disclosure.onClose();
                    dispatch(removeGraphView({ grahViewId: props.id }));
                  }}
                >
                  Close graph
                </Button>
              </Stack>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      ) : undefined}
    </Stack>
  );
  
}


export default function Sidebar({ isOpen, onToggle }: { isOpen: boolean; onToggle: () => void }) {

  const navigate = useNavigate();
  const location = useLocation();
  const auth = useAuth();
  const graphViews = useAppSelector((state) => state.viewReducer.graphViews);
  
  return (
    <Stack
      flexDirection="column"
      minWidth={{
        base: 'min-content',
        md: isOpen ? '30%' : 'min-content',
        xl: isOpen ? '18%' : 'min-content',
      }}
      maxWidth={{
        base: 'min-content',
        md: '30%',
        xl: '18%',
      }}
      height="100%"
      padding={{
        base: '2',
        md: '4',
      }}
      bg={theme.colors.gray[100]}
    >
      <Tooltip
        label="click to expand/minimize"
        bgColor={theme.colors.purple[700]}
        placement="right"
      >
        <Box
          display="flex"
          width="full"
          cursor="pointer"
          padding={{
            base: '0',
            xl: '4',
          }}
          justifyContent="center"
          alignItems="center"
          _hover={{ bg: theme.colors.purple[200], transition: 'background-color 0.2s' }}
          transition="background-color 0.2s"
          borderRadius="md"
          onClick={() => onToggle()}
          tabIndex={1}
        >
          <Image src="/logo512.png" height="16" minWidth="16" />
        </Box>
      </Tooltip>

      <Stack direction="column">
        {navItemsGroup.map((e, i) => {
          return (
            <NavItemButton
              key={e.label+i}
              id={e.label}
              text={e.label}
              textShort={e.label_short}
              active={location.pathname === e.path}
              disabled={e.disabled}
              icon={e.icon}
              isOpen={isOpen}
              textSize="medium"
              textColor="black"
              tabIndex={2 + (2 * i)}
              showMore={false}
              onClick={() => navigate(e.path)}
            />
          );
        })}
      </Stack>

      <Divider
        style={{ width: '100%', margin: 0, borderWidth: '2px', opacity: '80%' }}
        color="darkgray"
        width={1}
        orientation="horizontal"
      />

      <Text
        paddingX={2}
        pt={2}
        fontSize="sm"
        fontWeight="bold"
        textAlign={isOpen ? 'left' : 'center'}
      >
        Graphs
      </Text>

      <Stack direction="column" height="20em" overflowY="auto" whiteSpace="nowrap" spacing={2}>
        {Object.values(graphViews).map((view, i) => {
          // console.log(view);

          return (
            <NavItemButton
              id={view.id}
              text={view.name}
              key={view.id+i}
              textShort={view.name}
              active={location.pathname === `/graph/${view.id}`}
              disabled={false}
              icon={false}
              isOpen={isOpen}
              textSize="small"
              textColor="black"
              tabIndex={50 + (2 * i)}
              showMore={true}
              onClick={() => navigate(`/graph/${view.id}`)}
            />
          );
        })}
      </Stack>

      <Spacer />

      <Divider
        style={{ width: '100%', margin: 0, borderWidth: '2px', opacity: '80%' }}
        color="darkgray"
        width={1}
        orientation="horizontal"
      />

      <Stack direction="column">
        <NavItemButton
          id="feedback"
          text="Give us your feedback!"
          textShort="Feedback"
          active={location.pathname === '/feedback'}
          disabled={false}
          icon={<MessageSquare />}
          isOpen={isOpen}
          textSize="medium"
          textColor={theme.colors.purple[600]}
          tabIndex={100}
          showMore={false}
          onClick={() => window.open('https://nettskjema.no/a/463244')?.focus()}
        />
        <NavItemButton
          id="learn_more"
          text="Learn More"
          textShort="Learn More"
          active={location.pathname === '/learn_more'}
          disabled={false}
          icon={<HelpCircle />}
          isOpen={isOpen}
          textSize="medium"
          textColor="black"
          tabIndex={102}
          showMore={false}
          onClick={() => navigate('/learn_more')}
        />
        <NavItemButton
          id="settings"
          text="Settings"
          textShort="Settings"
          active={location.pathname === '/settings'}
          disabled={false}
          icon={<Settings />}
          isOpen={isOpen}
          textSize="medium"
          textColor="black"
          tabIndex={104}
          showMore={false}
          onClick={() =>
            window
              .open(
                `https://auth.meshr.org/realms/${process.env.REACT_APP_AUTH_REALM}/account/`,
                '_blank',
              )
              ?.focus()
          }
        />
        <NavItemButton
          id="logout"
          text="Log out"
          textShort="Log out"
          active={location.pathname === '/log_out'}
          disabled={false}
          icon={<LogOut />}
          isOpen={isOpen}
          textSize="medium"
          textColor="black"
          tabIndex={106}
          showMore={false}
          onClick={() => auth.signOutRedirect()}
        />
      </Stack>
    </Stack>
  );

}
