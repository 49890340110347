import { Stack, Icon, Text } from "@chakra-ui/react";
import React, { CSSProperties } from "react";
import { ChevronRight } from "react-feather";
import { useRef } from "react";
import { useOutsideClick, Box } from "@chakra-ui/react";
import { motion } from 'framer-motion';


export default function ContextMenu(props: {style?: CSSProperties, children: React.ReactNode, onClose: () => void}) {
    const ref = useRef(null);
    useOutsideClick({
        ref: ref,
        handler: () => props.onClose(),
      });
    return (
        <motion.div
            ref={ref}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.05 }}
            style={{
                ...props.style,
                position: 'absolute',
                backgroundColor: '#f0f0f0f0',
                padding: '7px',
                borderRadius: '3px',
                zIndex: 100,
                boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                width: '210px',
            }}
        >
            <Stack>
                {props.children}
            </Stack>
        </motion.div>
    );
}

function ContextMenuButton(props: { icon: any; text: string; onClick: () => void, submenu?: React.ReactNode }) {
    const [showSubmenu, setShowSubmenu] = React.useState(false);
    return (
        <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            cursor="pointer"
            onClick={props.onClick}
            _hover={{ backgroundColor: 'gray.200' }}
            borderRadius="5px"
            paddingTop={"3px"}
            paddingBottom={"3px"}
            paddingLeft={"5px"}
            paddingRight={"5px"}
            onMouseOver={(_) => setShowSubmenu(true)}
            onMouseLeave={(_) => setShowSubmenu(false)}
        >
            <Icon as={props.icon} />
            <Stack justifyContent={"space-between"} direction="row" width={"100%"} alignItems={"center"}>
                <Text userSelect="none" fontSize="sm" fontWeight={500} textAlign="center">
                    {props.text}
                </Text>
                {props.submenu ? <Icon as={ChevronRight} /> : null}
                {showSubmenu ? 
                <div style={{ position: 'absolute', left: 180, top: 110 }}>
                    {props.submenu}
                </div>
                
                : null}
            </Stack>
        </Stack>
    );
}

export { ContextMenuButton };