import {
  Graph as GraphAPIType,
  Node,
  Relationship,
  articleTypes,
  caseDetailTypes,
  chambertypes,
  countrytypes,
  importancetypes,
} from './api/APITypes';
import Graph from 'graphology';
import { GraphView } from './state/ViewTypes';
import { theme } from '@chakra-ui/theme';
import { v4 as uuidv4 } from 'uuid';

function graphFromData(data: GraphAPIType, centerNodeId: string, mergeGraph?: Graph): Graph {
  let centerNodeElementId: string | undefined = undefined;

  const graph = mergeGraph ? mergeGraph : new Graph({ type: 'mixed' });
  if (mergeGraph) {
    centerNodeElementId = graph.findNode(
      (node) => graph.getNodeAttributes(node).id === centerNodeId,
    );
  }

  for (let i = 0; i < data.nodes.length; i++) {
    let node = data.nodes[i];
    if (node.properties.id === centerNodeId) centerNodeElementId = data.nodes[i].elementId;
    addNodeToGraph(node, graph, node.properties.id === centerNodeId);
  }
  for (let i = 0; i < data.edges.length; i++) {
    let edge = data.edges[i];
    addEdgeToGraph(edge, graph, centerNodeElementId!);
  }
  setNodeSizeBasedOnDegree(graph, useOutDegree);

  return graph;
}

function addNodeToGraph(node: Node, graph: Graph, isCenter?: boolean) {
  if (graph.hasNode(node.elementId)) return;
  let position: { x: number; y: number } = findValidPosition(graph, node);

  graph.addNode(node.elementId, {
    label: node.properties.label?.replace('CASE OF', ''),
    id: node.properties.id,
    judgementdate: node.properties.judgementdate,
    nodeType: node.labels[0],
    importance: node.properties.importance,
    country: node.properties.country,
    conclusion: node.properties.conclusion,
    chambertype: node.properties.chambertype,
    separateopinion: node.properties.separateopinion,
    keywords: node.properties.keywords,
    x: position.x,
    y: position.y, //Math.random() * 200000000000 - 200000000000 / 2,
    color: isCenter
      ? theme.colors.purple[400]
      : getNodeColor(node.properties.importance!.toString()),
    originalColor: isCenter
      ? theme.colors.purple[400]
      : getNodeColor(node.properties.importance!.toString()),
    size: 10, //sets the initial size which is updated later
    type: 'border',
    originalBorderColor: getNodeBorderColor(
      node.properties.violation ?? '',
      node.properties.nonviolation ?? '',
    ),
    borderColor: getNodeBorderColor(
      node.properties.violation ?? '',
      node.properties.nonviolation ?? '',
    ),
    violation: node.properties.violation,
    nonviolation: node.properties.nonviolation,
    url: node.properties.url,
  });
}

function addEdgeToGraph(edge: Relationship, graph: Graph, centerNode: string) {
  if (graph.hasEdge(edge.elementId)) return;
  if (graph.hasEdge(edge.startNodeElementId, edge.endNodeElementId)) return;
  graph.addDirectedEdgeWithKey(edge.elementId, edge.startNodeElementId, edge.endNodeElementId, {
    label: edge.type,
    size: 2,
    hidden: !(edge.startNodeElementId === centerNode) && !(edge.endNodeElementId === centerNode),
    color: edge.type === 'recommend' ? theme.colors.purple[300] : 'gray',
  });
}

function getDefaultGraphView(centerNode: string): GraphView {
  let graphView: GraphView = {
    name: 'Graph',
    id: uuidv4(),
    centerNode: centerNode,
    floatingDocuments: [],
    serializedGraph: null,
    filter: {
      decisionLevel: chambertypes,
      importance: importancetypes,
      caseDetail: caseDetailTypes,
      articles: articleTypes,
      timeFilter: { lower: -946774800000, upper: new Date().getTime() },
      countrys: countrytypes,
    },
  };
  return graphView;
}

function findValidPosition(graph: Graph, inputNode: Node): { x: number; y: number } {
  let position = {
    x: new Date(inputNode.properties.judgementdate!).getTime(),
    y: Math.random() * 200000000000 - 200000000000 / 2,
  };

  const maxSize = 10000000000;

  if (graph.nodes().length === 0) return position;
  let overlap = true;

  while (overlap) {
    overlap = false; // Assume no overlap unless we find one

    graph.forEachNode((existingNode) => {
      const leftBoundExistingNode = graph.getNodeAttribute(existingNode, 'x') - maxSize;
      const rightBoundExistingNode = graph.getNodeAttribute(existingNode, 'x') + maxSize;

      const leftBoundInputNode = position.x - maxSize;
      const rightBoundInputNode = position.x + maxSize;

      const topBoundExistingNode = graph.getNodeAttribute(existingNode, 'y') + maxSize;
      const bottomBoundExistingNode = graph.getNodeAttribute(existingNode, 'y') - maxSize;

      const topBoundInputNode = position.y + maxSize;
      const bottomBoundInputNode = position.y - maxSize;

      if (
        (leftBoundExistingNode <= rightBoundInputNode &&
          rightBoundInputNode <= rightBoundExistingNode) ||
        (rightBoundExistingNode >= leftBoundInputNode &&
          leftBoundInputNode >= leftBoundExistingNode)
      ) {
        if (
          (topBoundExistingNode >= bottomBoundInputNode &&
            bottomBoundInputNode >= bottomBoundExistingNode) ||
          (bottomBoundExistingNode <= topBoundInputNode &&
            topBoundInputNode <= topBoundExistingNode)
        ) {
          position.y += 1000000000;
          overlap = true;
        }
      }
    });
  }
  return position;
}

function setNodeSizeBasedOnDegree(graph: Graph, useOutDegree: boolean) {
  //also updates when you expand neighbours
  //todo: update when reccomended neighbours (undirected edge?)

  graph.forEachNode((node) => {
    const degree = useOutDegree ? graph.outDegree(node) : graph.inDegree(node);
    let nodeSize = 10;
    if (degree < 10) {
      nodeSize = 10;
    } else if (degree > 30) {
      nodeSize = 30;
    } else {
      nodeSize = nodeSize + degree;
    }
    graph.setNodeAttribute(node, 'size', nodeSize);
  });
}

let useOutDegree = true;

//todo: put in filteractions and use this later to switch between in and out degree to determine size
function toggleDegree(graph: Graph) {
  useOutDegree = !useOutDegree;
  setNodeSizeBasedOnDegree(graph, useOutDegree);
}

function getNodeColor(type: string) {
  switch (type) {
    case 'Keyword':
      return '#fff873';
    case 'Article':
      return '#065666';
    case 'Paragraph':
      return '#ff6e6e';
    case 'SubParagraph':
      return '#ffbfbf';
    case 'Document':
      return '#6B46C1';
    case '0':
      return theme.colors.green[400];
    case '1':
      return theme.colors.blue[900];
    case '2':
      return theme.colors.blue[600];
    case '3':
      return theme.colors.blue[300];
    case '4':
      return theme.colors.blue[100];
    default:
      return '#697aff';
  }
}

function getNodeBorderColor(violation: string, nonviolation: string) {
  if (violation && nonviolation) {
    return theme.colors.orange[400];
  } else if (violation) {
    return theme.colors.red[600];
  } else if (nonviolation) {
    return theme.colors.green[600];
  }
  return theme.colors.blue[500];
}

function copyToMutableGraph(immutableGraph: Graph) {
  let mutableGraph = new Graph({ type: 'mixed' });
  immutableGraph.forEachNode((node) =>
    mutableGraph.addNode(node, { ...immutableGraph.getNodeAttributes(node) }),
  );
  immutableGraph.forEachEdge((edge) =>
    mutableGraph.addEdge(immutableGraph.source(edge), immutableGraph.target(edge), {
      ...immutableGraph.getEdgeAttributes(edge),
    }),
  );
  return mutableGraph;
}

export {
  graphFromData,
  getNodeColor,
  getNodeBorderColor,
  addNodeToGraph,
  addEdgeToGraph,
  getDefaultGraphView,
  setNodeSizeBasedOnDegree,
  copyToMutableGraph,
};
